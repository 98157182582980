"use strict";

jQuery(document).ready(function ($) {
  // Initialize foundation
  $(document).foundation();

  // Selectors
  var menuBtn = $('.menu-btn');
  var nav = $('nav');
  var lineOne = $('.menu-btn .line--1');
  var lineTwo = $('.menu-btn .line--2');
  var lineThree = $('.menu-btn .line--3');
  var link = $('nav .nav-links');
  var minicartheader = $('.mini-cart');
  var minicart = $(".dropdown-menu-mini-cart");
  var openQuoteBtn = $('.openQuote');
  var fixedProductBar = $('.fixed-product-bar');
  var closePopupBtn = $('#closePopup');
  var ul = $("nav#site-navigation ul li ul");
  var li = $("nav#site-navigation ul li ul li");
  var searchSection = $('section');
  var body = $('body');
  var fadeInSection = $('.fade-in');
  var header = $('.site-header');

  // Event listener for menu button
  menuBtn.on('click', function () {
    nav.toggleClass('nav-open');
    header.toggleClass('nav-open');
    menuBtn.toggleClass('nav-open');
    lineOne.toggleClass('line-cross');
    lineTwo.toggleClass('line-fade-out');
    lineThree.toggleClass('line-cross');
    link.toggleClass('fade-in');
  });

  // Function to check if an element is scrolled into view
  function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + ($(window).height() + 200);
    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();
    return elemBottom <= docViewBottom && elemTop >= docViewTop;
  }

  // Mini cart functionality
  minicartheader.click(function () {
    minicart.slideToggle();
    $(".account-label i").toggleClass('open');
  });

  // Popup functionality
  if (openQuoteBtn.length) {
    openQuoteBtn.click(function () {
      $('html, body').animate({
        scrollTop: 0
      }, 50);
      $('.quote-popup-container').css("display", "flex").hide().fadeIn();
    });
  }

  // $('#gform_3').on('submit', function(event) {
  //     // Temporarily remove event.preventDefault() for debugging
  //     // event.preventDefault(); // Prevent default form submission
  //
  //     // Log form action URL
  //     console.log("Form action URL:", $(this).attr('action'));
  //
  //     $.ajax({
  //         type: 'POST',
  //         url: $(this).attr('action'),
  //         data: $(this).serialize(),
  //         success: function (response) {
  //             // Log success response
  //             console.log("AJAX success response:", response);
  //
  //             // Hide elements on success
  //             jQuery('.quote-popup-container .quote-popup .form h2').hide();
  //             jQuery('.quote-popup-container .quote-popup .form .product-container').hide();
  //         },
  //         error: function(xhr, status, error) {
  //             // Log AJAX error
  //             console.error("AJAX error:", error);
  //         }
  //     });
  // });

  if (closePopupBtn.length) {
    closePopupBtn.click(function () {
      $('.quote-popup-container').fadeOut();
    });
  }

  // Add expandChild link for menu items with children
  if (ul.hasClass("children")) {
    ul.parent(li).addClass("hasChildren");
  }
  if (li.hasClass("hasChildren")) {
    $("li.hasChildren a").append("<a href='javascript:void(0)' class='expandChild' title='Child item'></a>");
  }

  // Close navigation on outside click
  $(document).mouseup(function (e) {
    if (!nav.is(e.target) && nav.has(e.target).length === 0) {
      nav.removeClass('open');
    }
  });
  jQuery('.custom-file-upload-container .ginput_container_fileupload input[type=file]').change(function () {
    var fileName = $(this).val().split('\\').pop();
    var closestContainer = $(this).closest('.custom-file-upload-container').find('.custom-label');
    $(this).closest('.custom-file-upload-container').find('.gform-field-label').hide();
    // Do something with the closest container
    closestContainer.text(fileName);
  });

  // Search functionality
  $('.open-search-from').click(function () {
    searchSection.addClass("open-search");
    body.addClass("search-main");
  });
  $('#deleteSearch').click(function () {
    searchSection.removeClass("open-search");
    body.removeClass("search-main");
  });

  // Fade in elements on scroll
  $(window).on('scroll touchmove', function () {
    fadeInSection.each(function () {
      if (isScrolledIntoView($(this))) {
        $(this).addClass('animation-started');
      }
    });
  });
  var galleryThumbs = new Swiper(".gallery-thumbs", {
    centeredSlidesBounds: true,
    direction: "horizontal",
    spaceBetween: 10,
    slidesPerView: 3,
    freeMode: false,
    breakpoints: {
      480: {
        direction: "vertical",
        slidesPerView: 4
      }
    }
  });
  var uspsslider = new Swiper('.usps-slider', {
    loop: true,
    grabCursor: true,
    slidesPerView: 1,
    breakpoints: {
      767: {
        slidesPerView: 4
      }
    }
  });
  var galleryImage = new Swiper(".gallery-image", {
    direction: "horizontal",
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-product-next",
      prevEl: ".swiper-product-prev"
    },
    a11y: {
      prevSlideMessage: "Previous slide",
      nextSlideMessage: "Next slide"
    },
    keyboard: {
      enabled: true
    },
    thumbs: {
      swiper: galleryThumbs
    }
  });

  // var galleryThumbs = new Swiper('.gallery-thumbs', {
  //     spaceBetween: 10,
  //     slidesPerView: 4,
  //     freeMode: true,
  //     watchSlidesVisibility: true,
  //     watchSlidesProgress: true,
  // });

  var galleryTop = new Swiper('.gallery-top', {
    spaceBetween: 10,
    loop: true,
    grabCursor: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  });
  if (fixedProductBar.length) {
    var threshold = $(window).height(); // Get the height of the viewport
    $(window).scroll(function () {
      // Check if the vertical scroll position has passed a threshold
      if ($(window).scrollTop() > threshold) {
        // Adjust 500 to your desired threshold
        fixedProductBar.slideDown(); // Show the below-fold div
      } else {
        fixedProductBar.slideUp(); // Hide the below-fold div
      }
    });
  }

  // Initialize Magnific Popup
  $('.magnific-popup').magnificPopup({
    type: 'image',
    mainClass: ' mfp-with-zoom',
    removalDelay: 300,
    gallery: {
      enabled: true
    }
  });
  $('.embed-container').magnificPopup({
    // disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false
  });
  $('#product-content-btn').click(function () {
    $('#product-content').addClass('read-all');
    $('#product-content-btn').hide();
  });
  $('.content-btn').click(function () {
    $(this).prev('.content-text').addClass('read-all');
    $(this).hide();
  });

  // // Header scroll behavior
  // let didScroll;
  // let lastScrollTop = 0;
  // const delta = 5;
  // const navbarHeight = header.outerHeight();
  //
  // $(window).scroll(function (event) {
  //     didScroll = true;
  // });
  //
  // setInterval(function () {
  //     if (didScroll) {
  //         hasScrolled();
  //         didScroll = false;
  //     }
  // }, 250);
  //
  // function hasScrolled() {
  //     const st = $(window).scrollTop();
  //
  //     if (Math.abs(lastScrollTop - st) <= delta) {
  //         return;
  //     }
  //
  //     if (st > lastScrollTop && st > navbarHeight) {
  //         header.removeClass('nav-down').addClass('nav-up');
  //
  //         if (nav.hasClass("nav-open")) {
  //             nav.removeClass('nav-open');
  //             menuBtn.removeClass('nav-open');
  //             lineOne.toggleClass('line-cross');
  //             lineTwo.toggleClass('line-fade-out');
  //             lineThree.toggleClass('line-cross');
  //         }
  //     } else {
  //         if (st + $(window).height() < $(document).height()) {
  //             header.removeClass('nav-up').addClass('nav-down');
  //         }
  //     }
  //
  //     lastScrollTop = st;
  // }
});

// Touch events configuration
jQuery.event.special.touchstart = {
  setup: function setup(_, ns, handle) {
    this.addEventListener('touchstart', handle, {
      passive: !ns.includes('noPreventDefault')
    });
  }
};
jQuery.event.special.touchmove = {
  setup: function setup(_, ns, handle) {
    this.addEventListener('touchmove', handle, {
      passive: !ns.includes('noPreventDefault')
    });
  }
};